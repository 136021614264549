<template>
  <div class="examination-management">
    <div style="float: right;">
      <el-button type="primary" style="float: right;width: 120px;" @click="copy">分享鉴定点</el-button>
    </div>
    <el-table class="customTable" :data="tableData" style="width: 100%; margin-top: 20px; flex: 1" height="1%"
      size="medium" :header-cell-style="{
        fontWeight: 'normal',
        height: '60px',
        color: '#666666',
        background: '#F6F6F6',
        fontSize: '16px',
      }" :cell-style="{ fontSize: '16px', color: '#333333', height: '70px' }">
      <el-table-column prop="tid" label="序号" width="90" align="center">
      </el-table-column>
      <el-table-column prop="apply_school" label="申请单位" width="180" align="center">
      </el-table-column>
      <el-table-column prop="school_contact_user" label="联系人" width="180" align="center">
      </el-table-column>
      <el-table-column prop="school_contact_mobile" label="联系人电话" align="center" width="250">
      </el-table-column>
      <el-table-column prop="examination_room_name" label="考场名称" align="center">
      </el-table-column>
      <el-table-column prop="create_time" label="申请时间" width="280" align="center">
      </el-table-column>
      <!-- <el-table-column
        prop="examtime"
        label="申请考试时间"
        width="400"
        align="center"
      >
        <template slot-scope="scope">
          <span
            >{{ scope.row.examination_start_time }} ~
            {{ scope.row.examination_end_time }}</span
          >
        </template>
      </el-table-column> -->
      <el-table-column prop="examination_category_id" label="考级级别" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.examination_category_id == 1 ? "3级" : "4级" }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-link type="success" :underline="false" @click="adoptApply(scope.row)" v-if="scope.row.status == 0">通过
          </el-link>
          <span style="font-size: 13px; color: rgb(28, 176, 100)" v-if="scope.row.status == 3">创建成功</span>
          <span style="font-size: 13px; color: rgba(255, 0, 0, 1)" v-if="scope.row.status == 2">审核未通过</span>
          <el-link type="danger" :underline="false" @click="cancelApply(scope.row)" v-if="scope.row.status == 0">驳回
          </el-link>
          <el-button type="primary" v-if="scope.row.status == 1" @click="createExam(scope.row)">创建考场</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="pager-center" style="margin: 20px 0 30px; text-align: center"
      :current-page="examinationPages.currentPageNum" :page-size="examinationPages.eachPageNum"
      :total="examinationPages.total" layout="prev, pager, next, jumper" @current-change="CurrentChange">
    </el-pagination>

    <!-- 审核 -->
    <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" :title="windowTitle"
      custom-class="green-dialog" :visible.sync="showWindow" @close="closeWindow" width="500px">
      <div class="status">
        <span style="text-align: center;font-size:16px;">您确认通过该审核吗？</span>
        <div style="margin-top: 15px;">
          <span style="margin-right:5px">考场地点：</span>
          <el-input v-model="statusLocation" style="width: 200px;"></el-input>
        </div>
        <div style="margin-top: 20px;">
          <el-button @click="closeWindow">取消</el-button>
          <el-button @click="updateStatus" type="primary">审核</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 创建考场会话框 -->
    <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" :title="examDialogTitle"
      custom-class="green-dialog" :visible.sync="examDialogVisible" @close="resetForm" @opened="openForm" width="500px">
      <el-form :model="form" ref="form" :rules="examinationRules">
        <el-form-item label="考场名称" prop="name">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="考场地点" prop="location">
          <el-input v-model="form.location" autocomplete="off"></el-input>
        </el-form-item>
        <!-- <el-form-item label="考场考试时间" style="width: 130px" prop="timeDate">
          <el-date-picker
            v-model="form.timeDate"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 450px"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item> -->
        <el-form-item label="考试级别" prop="level">
          <el-select v-model="form.examLevel" placeholder="请选择" style="width: 450px">
            <el-option v-for="item in level" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="申请人" prop="applyname">
          <el-input v-model="form.applyname" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="申请人电话" prop="applytel">
          <el-input v-model="form.applytel" autocomplete="off" onkeyup="value=value.replace(/[^\d]/g,'')"></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button @click="examDialogVisible = false">取 消</el-button>
        <el-button style="margin-left: 30px" type="primary" @click="confirmForm('form')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { showApproval, editApproval, addExam } from "@/utils/apis";
import ClipboardJS from "clipboard";
export default {
  name: "Approval",
  data() {
    return {
      // TimeData: [],
      level: [
        {
          value: 1,
          label: "3级",
        },
        {
          value: 2,
          label: "4级",
        },
      ],
      flag: 0,
      statusId: 0,
      examDialogTitle: "", //显示会话框标题
      examDialogVisible: false, //显示会话框
      showWindow: false,
      windowTitle: "审核",
      shareLocation: '',
      statusLocation: '',
      examinationPages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
      tableData: [],
      form: {
        id: "",
        name: "",
        location: "",
        timeDate: [],
        applyname: "",
        applytel: "",
        examLevel: "",
      },
      examinationRules: {
        name: [
          { required: true, message: "请输入考场名称", trigger: "blur" },
          {
            min: 3,
            max: 50,
            message: "长度在 3 到 50 个字符",
            trigger: "blur",
          },
        ],
        location: [
          { required: true, message: "请输入考场地点", trigger: "blur" },
          {
            min: 3,
            max: 50,
            message: "长度在 3 到 50 个字符",
            trigger: "blur",
          },
        ],

        applyname: [
          { required: true, message: "请输入申请人", trigger: "blur" },
          {
            min: 2,
            max: 10,
            message: "长度在 2 到 10 个字符",
            trigger: "blur",
          },
        ],
        applytel: [
          { required: true, message: "请输入申请人电话", trigger: "blur" },
          {
            min: 11,
            max: 11,
            message: "长度在 11 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.showExaminationListApprove();
    this.shareLocation = localStorage.getItem('check_center_id')
    console.log(this.shareLocation)
  },
  methods: {
    copy() {
      let host = localStorage.getItem("hosturl");
      var input = document.createElement("input"); // js创建一个input输入框
      input.value = `${host}apply?id=${this.shareLocation}`; // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input); // 将输入框暂时创建到实例里面
      input.select(); // 选中输入框中的内容
      document.execCommand("Copy"); // 执行复制操作
      document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
      this.$message.success({
        type: "success",
        message: "地址复制成功",
      });
    },
    showExaminationListApprove() {
      let params = {
        page: this.examinationPages.currentPageNum,
        limit: this.examinationPages.eachPageNum,
      };
      showApproval(params).then((res) => {
        if (res.code === 200) {
          console.log(res.data);
          this.tableData = res.data.list;
          this.examinationPages.total = res.data.total;
          let num = 0;
          this.tableData.map((item) => {
            item.flag = 0;
            item.tid = num += 1;
            return item;
          });
        }
      });
    },
    adoptApply(row) {
      this.showWindow = true
      this.statusId = row.id
      // this.$confirm(`您确认通过该审核吗？`, "操作确认", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   customClass: "green-message-box",
      //   type: "warning",
      // })
      // .then(() => {
      //   let params = {
      //     id: row.id,
      //     status: 1,
      //   };
      //   editApproval(params).then((res) => {
      //     if (res.code === 200) {
      //       this.$message({
      //         type: "success",
      //         message: res.msg,
      //       });
      //       this.showExaminationListApprove();
      //     }
      //   });
      // })
      // .catch(() => {
      //   this.$message({
      //     type: "info",
      //     message: "已取消审核",
      //   });
      // });
    },
    updateStatus() {
      let params = {
        id: this.statusId,
        status: 1,
      };
      if (this.statusLocation == '') {
        return this.$message.warning("请输入考场地点")
      } else {
        params.examination_room_address = this.statusLocation
      }
      editApproval(params).then((res) => {
        if (res.code === 200) {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.statusLocation = "",
            this.showWindow = false
          this.showExaminationListApprove();
        }
      }).catch(err => [
        console.log(err)
      ]);
    },
    closeWindow() {
      this.statusId = 0,
        this.showWindow = false,
        this.statusLocation = ""
    },
    // 审核通过创建考场
    createExam(row) {
      this.form.id = row.id;
      this.form.name = row.examination_room_name;
      // this.TimeData.push(row.examination_start_time, row.examination_end_time);
      this.form.applyname = row.school_contact_user;
      this.form.applytel = row.school_contact_mobile;
      this.form.examLevel = row.examination_category_id;
      this.examDialogTitle = "创建考场";
      this.examDialogVisible = true;
    },
    openForm() {
      // this.form.timeDate = this.TimeData;
    },
    // 关闭
    resetForm() {
      // this.TimeData = [];
      this.$refs.form.resetFields();
    },
    cancelApply(row) {
      this.$confirm(
        `您确认驳回该申请吗？如确认后学校将无法通过审核，该申请将会在列表中删除`,
        "驳回",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          customClass: "green-message-box",
          type: "warning",
        }
      )
        .then(() => {
          let params = {
            id: row.id,
            status: 2,
          };
          editApproval(params).then((res) => {
            if (res.code === 200) {
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.showExaminationListApprove();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消驳回",
          });
        });
    },
    // 提交
    confirmForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            apply_id: this.form.id,
            examination_room_name: this.form.name,
            examination_room_address: this.form.location,
            // examination_start_time: JSON.stringify(
            //   new Date(this.form.timeDate[0]).getTime()
            // ).substring(0, 10),
            // examination_end_time: JSON.stringify(
            //   new Date(this.form.timeDate[1]).getTime()
            // ).substring(0, 10),
            apply_user: this.form.applyname,
            apply_mobile: this.form.applytel,
            examination_category_id: this.form.examLevel,
          };
          addExam(params)
            .then((res) => {
              if (res.code === 200) {
                this.$message({
                  type: "success",
                  message: res.msg,
                });
              }
              this.examDialogVisible = false;
              this.tableData.forEach((item) => {
                if (item.id == this.form.id) {
                  item.flag = 1;
                }
              });
              this.showExaminationListApprove();
            })
            .catch((err) => {
              console.log("err", err);
            });
        }
      });
    },
    CurrentChange(val) {
      this.examinationPages.currentPageNum = val;
      this.showExaminationListApprove();
    },
  },
};
</script>

<style lang="scss" scoped>
.examination-management {
  padding: 0px 20px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;

  .status {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>